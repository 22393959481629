import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import VueSocketIO from "vue-socket.io";

import "./assets/style.sass";

const socket: any = new VueSocketIO({
  connection: "https://propellercat.com/",
  vuex: {
    store,
    actionPrefix: "SOCKET_"
  }
})

// console.log(socks);
// setInterval(() => {
//   socks.emit("TEST", "tester")
// }, 10000);


createApp(App)
  .use(store)
  .use(router)
  .mount("#app");
