
import { Options, Vue } from "vue-class-component";
import { State, Action } from "vuex-class";

import Printer from "@/components/Printer.vue";
import SVGMap from "@/components/SVGMap.vue"; // @ is an alias to /src

@Options({
  components: { Printer, SVGMap },
})
export default class Main extends Vue {
  @State contentStyles!: any[];
  @State svgZoom!: number;
  @State socketCount!: any[];
  @Action _setContentStyle: any;

  moveDown: boolean = false;
  selected: number = -1;

  mounted() {
    // setTimeout(() => {
    // }, 9000);
    // (this as any).$socket.emit("TEST", "tester");
    console.log(this);
  }
  makeTransform(key: number) {
    // let data: any = this.contentStyles[key];
    // return {
    //   transform: "translateX(" + data.x + "px) translateY(" + data.y + "px)",
    //   "transform-origin": "50% 50%",
    // };
    return {
      transform: "translateX(" + 0 + "px) translateY(" + 0 + "px)",
      "transform-origin": "50% 50%",
    };
  }

  mainMousedown(e: any, ix: number) {
    if (e.buttons === 1) {
      this.selected = ix;
      this.moveDown = true;
    }
  }

  mainMouseup(e: any) {
    this.moveDown = false;
  }

  mainMousemove(e: any) {
    if (this.moveDown) {
      let x = this.contentStyles[this.selected].x + e.movementX / this.svgZoom;
      let y = this.contentStyles[this.selected].y + e.movementY / this.svgZoom;
      // this._setContentStyle([this.selected, "x", x]);
      // this._setContentStyle([this.selected, "y", y]);
    }
  }
}
