
import { Options, Vue } from "vue-class-component";
import { State } from "vuex-class";

const discd = 9
const foncd = 3 // font size

@Options({
  props: {
    msg: String,
  },
})
export default class SVGMap extends Vue {
  @State stats!: any;
  @State monitors!: any;

  pos: any = {
    o: {
      x: discd,
      y: foncd / 2,
    },
    w: {
      x: -discd,
      y: foncd / 2,
    },
    n: {
      x: 0,
      y: -discd + foncd / 2,
    },
    so: {
      x: discd - 2,
      y: discd + foncd / 2 - 2,
    },
    sw: {
      x: -discd + 2,
      y: discd + foncd / 2 - 2,
    },
    s: {
      x: 0,
      y: discd + foncd / 2,
    },
    no: {
      x: discd - 2,
      y: -discd + foncd / 2 + 2,
    },
    nw: {
      x: -discd + 2,
      y: -discd + foncd / 2 + 2,
    },
    n_v1: {
      x: 0,
      y: -discd + foncd / 2 - 9.6,
    },
    nw_v1: {
      x: -discd,
      y: -discd + foncd / 2,
    },
  };

  get mainplainstyle() {
    return {
      transform: "scale(" + 1 + ")",
      "-moz-transform": "scale(" + 1 + ")",
      "-webkit-transform": "scale(" + 1 + ")",
      "transform-origin": "50% 50%",
    };
  }
}
