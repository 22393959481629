import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import actions from "./actions";

const stats: any = require('./stats.json')

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: "ubahnPlann",
  reducer: (state: any) => {
    return {
      contentStyles: state.contentStyles,
      svgZoom: state.svgZoom,
      svgMoveX: state.svgMoveX,
      svgMoveY: state.svgMoveY,
    };
  }
});

const plugins = [vuexLocal.plugin];

export default createStore({
  plugins,
  state: {
    stats,
    monitors: {},
    user: {},
    socketCount: 1,
    
    svgZoom: 1,
    svgMoveX: 1,
    svgMoveY: 1,
    contentStyles: [
      {
        x: 123,
        y: 123,
        "max-width": "720px",
      },
    ]
  },
  mutations: {
    set(state: any, v: any) {
      let a: any, b: any, c: any, d: any;
      [a, b, c, d] = v;
      if (typeof d != "undefined") {
        if (!state[a][b]) state[a][b] = {};
        state[a][b][c] = c;
      } else if (typeof c != "undefined") {
        state[a][b] = c;
      } else {
        state[a] = b;
      }
      state[a] = b;
    },
    setContentStyle(state: any, v: any) {
      let b: any, c: any, d: any;
      [b, c, d] = v;
      state['contentStyles'][b][c] = d;
    }
  },
  actions: { ...actions },
  modules: {}
});