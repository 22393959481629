const actions: any = {
  _set({ commit }: any, v: any) {
    commit("set", v);
  },
  _setContentStyle({ commit }: any, v: any) {
    commit("setContentStyle", v);
  },


  SOCKET_CONNECT: ({ state }: any, status: any) => {
    console.log('SOCKET_CONNECT')
    // state.connect = true
  },
  SOCKET_TEST: ({ state }: any, data: any) => {
    console.log('gotit!',data)
    state.monitors = data
  },
  SOCKET_GEO: ({ state }: any, status: any) => {
    console.log('GEO!', status)
    // xtore.commit('set', ['geo', status])
  },
  SOCKET_ALL: ({ state }: any, status: any) => {
    console.log('ALL', status)
  },
  SOCKET_SUCKS: ({ state }: any, status: any) => {
    console.log('SUCKS!', status)
    state.socketCount = status
  }
}

export default actions