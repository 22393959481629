<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <router-view />
</template>



<script>
function zoom(e) {
  console.log(e.scale);
  e.preventDefault();
}
document.addEventListener("gesturestart", zoom);
document.addEventListener("gesturechange", zoom);
document.addEventListener("gestureend", zoom);

</script>

<style lang="scss">
</style>
