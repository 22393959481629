
import { Options, Vue } from "vue-class-component";
import { Action } from "vuex-class";
import _ from "lodash";

const MAXZOOM = 50;
const MINZOOM = 0.25;
const MAXBORDER = 10000;
const MINBORDER = -10000;

let clientX: number, clientY: number, zoomStart: number;

let that: any = null;

@Options({
  beforeCreate() {
    that = this;
  },
  watch: {
    svgZoom: {
      handler: _.debounce((nv: any) => {
        that._set(["svgZoom", nv]);
      }, 500),
    },
    svgMoveX: {
      handler: _.debounce((nv: any) => {
        that._set(["svgMoveX", nv]);
      }, 500),
    },
    svgMoveY: {
      handler: _.debounce((nv: any) => {
        that._set(["svgMoveY", nv]);
      }, 500),
    },
  },
  computed: {
    mainBridge() {
      const that: any = this;
      return { width: that.innerwidth, height: that.innerheight };
    },

    svgStyle() {
      return {
        transform:
          "scale(" +
          this.svgZoom +
          ") translateX(" +
          (this.innerwidth / 2 + this.svgMoveX) +
          "px) translateY(" +
          (this.innerheight / 2 + this.svgMoveY) +
          "px)",
        "transform-origin": "50% 50%",
      };
    },

    rotated() {
      const that: any = this;

      if (that.rotar == 0) {
        return (
          "transform : translate(-500px,-500px) rotateZ(" + that.rotar + "deg)"
        );
      } else if (that.rotar == 90) {
        return (
          "transform : translate(-500px,-500px) rotateZ(" + that.rotar + "deg)"
        );
      } else if (that.rotar == 180) {
        return (
          "transform : translate(-500px,-500px) rotateZ(" + that.rotar + "deg)"
        );
      } else if (that.rotar == 270) {
        return (
          "transform : translate(-500px,-500px) rotateZ(" + that.rotar + "deg)"
        );
      } else if (that.rotar == 360) {
        that.rotar = 0;
        return (
          "transform : translate(-500px,-500px) rotateZ(" + that.rotar + "deg)"
        );
      } else if (that.rotar == -90) {
        that.rotar = 270;
      }
    },
  },

  created() {
    this.rotar = 0;
  },
})
export default class Printer extends Vue {
  @Action _set: any;

  triggerZoom: number = 3;
  innerwidth: number = 1280;
  innerheight: number = 1280;

  rotar: number = 0;
  svgZoom: number = 1;
  svgMoveX: number = 0;
  svgMoveY: number = 0;

  // @State svgZoom!: number

  inited: boolean = false;
  svg_style_bogie: any = { transform: "translateX(40px) translateY(34px)" };
  mousePos: any = { x: 240, y: 240 };
  mainBoard: any = { x: 240, y: 240 };

  oneTouch: boolean = false;
  thwoTouch: boolean = false;

  spacekeydown: boolean = false;
  middleclick: boolean = false;
  DC: boolean = false;
  DC_timer: boolean = false;

  mounted() {
    let that: any = this;

    this.svgZoom = that.$store.state.svgZoom || 1;
    this.svgMoveX = that.$store.state.svgMoveX || 1;
    this.svgMoveY = that.$store.state.svgMoveY || 1;

    this.init();
    this.initAppKeyPresses();

    (this.$refs.printer as any).onwheel = function (event: any) {
      event.preventDefault();
    };

    (this.$refs.printer as any).onmousewheel = function (event: any) {
      event.preventDefault();
    };
  }

  init() {
    let that: any = this;
    const printer: any = this.$refs.printer || null;
    if (printer) {
      const wh = printer.getBoundingClientRect() || {};
      this.innerheight = wh.height || 1;
      this.innerwidth = wh.width || 1;
      that.offY = window.innerHeight / 2;
      that.offX = window.innerWidth / 2;
      that.mainBoard = that.$refs.mainBridgeSvg.getBoundingClientRect();
    }
  }

  moveFkt(e: any, mfactor: number) {
    let that: any = this;
    that.svgMoveX = that.svgMoveX + (-e.deltaX / that.svgZoom) * mfactor;
    that.svgMoveY = that.svgMoveY + (-e.deltaY / that.svgZoom) * mfactor;
    that.BorderCheck();
  }

  BorderCheck() {
    let that: any = this;
    if (that.svgMoveX > MAXBORDER) that.svgMoveX = MAXBORDER;
    else if (that.svgMoveX < MINBORDER) that.svgMoveX = MINBORDER;
    if (that.svgMoveY > MAXBORDER) that.svgMoveY = MAXBORDER;
    else if (that.svgMoveY < MINBORDER) that.svgMoveY = MINBORDER;
  }

  mainMousemove(e: any) {
    let that: any = this;

    if (that.click) {
      let c = that.points[that.selectedId];
      let cut = e.altKey ? 1 : 10;
      let cx = that.n((e.x - that.offX) / that.svgZoom - that.svgMoveX, cut); // + window.pageXOffset
      let cy = that.n((e.y - that.offY) / that.svgZoom - that.svgMoveY, cut); // + window.pageYOffset
      // if (e.shiftKey) {
      //   c.x = that.MoveStarter.x;
      //   c.y = that.MoveStarter.y;
      //   if (
      //     Math.abs((that.MoveStarter.y - cy) / (that.MoveStarter.x - cx)) > 1
      //   ) {
      //     c.y = cy;
      //   }
      //   if (
      //     Math.abs((that.MoveStarter.y - cy) / (that.MoveStarter.x - cx)) < 1
      //   ) {
      //     c.x = cx;
      //   }
      // } else {
      c.x = cx;
      c.y = cy;
      // }
    }

    if (this.middleclick) {
      this.svgMoveX = this.svgMoveX + (e.movementX / this.svgZoom) * 1;
      this.svgMoveY = this.svgMoveY + (e.movementY / this.svgZoom) * 1;
      this.BorderCheck();
    }
    that.mousePos = { x: e.clientX, y: e.clientY };
  }

  mainMouseup(e: any) {
    let that: any = this;
    if (that.selectedId) {
      let c = that.points[that.selectedId];
      c.color = "#eeeeee";
      c.r = 3;
    }
    that.click = false;
    that.selectedId = "";
  }

  n(val: number, cut: number) {
    return Math.round(val / cut) * cut;
  }

  mainTouchStart(e: any) {
    if (e.touches.length == 1) {
      clientX = e.touches[0].clientX;
      clientY = e.touches[0].clientY;
      this.oneTouch = true;
      this.thwoTouch = false;
    } else if (e.touches.length == 2) {
      this.thwoTouch = true;
      this.oneTouch = false;

      const x1 = e.touches[0].clientX - e.touches[1].clientX;
      const y1 = e.touches[0].clientY - e.touches[1].clientY;
      let pytagoras = (x1 ** 2 + y1 ** 2) ** (1 / 2);
      zoomStart = pytagoras;

      const xx1 = (e.touches[0].clientX + e.touches[1].clientX) / 2;
      const yy1 = (e.touches[0].clientY + e.touches[1].clientY) / 2;

      clientX = xx1;
      clientY = yy1;
    }
  }

  mainTouchEnd(e: any) {
    this.oneTouch = false;
    this.thwoTouch = false;
    if (e.touches.length == 1) {
      console.log("");
    } else if (e.touches.length == 2) {
      const x1 = e.touches[0].clientX - e.touches[1].clientX;
      const y1 = e.touches[0].clientY - e.touches[1].clientY;
      let pytagoras = (x1 ** 2 + y1 ** 2) ** (1 / 2);
      zoomStart = pytagoras;
    }
  }

  mainTouchmove(e: any) {
    if (e.touches.length == 1 && this.oneTouch) {
      // // if (e.touches.length == 1) {
      const eX = -e.touches[0].clientX + clientX;
      const eY = -e.touches[0].clientY + clientY;
      clientX = e.touches[0].clientX;
      clientY = e.touches[0].clientY;

      const difX = this.svgMoveX - (eX / this.svgZoom) * 1;
      const difY = this.svgMoveY - (eY / this.svgZoom) * 1;
      if (difX) this.svgMoveX = difX;
      if (difY) this.svgMoveY = difY;

      this.BorderCheck();
    } else if (e.touches.length == 2 && this.thwoTouch) {
      // } else if (e.touches.length == 2) {
      const x1 = e.touches[0].clientX - e.touches[1].clientX;
      const y1 = e.touches[0].clientY - e.touches[1].clientY;
      let pytagoras = (x1 ** 2 + y1 ** 2) ** (1 / 2);

      const xx1 = (e.touches[0].clientX + e.touches[1].clientX) / 2;
      const yy1 = (e.touches[0].clientY + e.touches[1].clientY) / 2;

      const deltaY = (zoomStart || 1) - pytagoras;
      zoomStart = pytagoras;

      that.mousePos = { x: xx1, y: yy1 };
      this.zoomFkt({ deltaY }, 200);

      const xxx1 = -xx1 + clientX;
      const yyy1 = -yy1 + clientY;
      clientX = xx1;
      clientY = yy1;

      const difX = this.svgMoveX - (xxx1 / this.svgZoom) * 1;
      const difY = this.svgMoveY - (yyy1 / this.svgZoom) * 1;
      if (difX) this.svgMoveX = difX;
      if (difY) this.svgMoveY = difY;
      // this.BorderCheck();
    }
  }

  zoomFkt(e: any, zfactor: number) {
    let that: any = this;
    let oldzoom = that.svgZoom;
    // console.log(e.deltaY);
    that.svgZoom += -((e.deltaY / zfactor) * that.svgZoom);
    if (that.svgZoom <= MINZOOM) {
      that.svgZoom = MINZOOM;
    } else if (that.svgZoom >= MAXZOOM) {
      that.svgZoom = MAXZOOM;
    }
    let xxx = that.mousePos.x - that.mainBoard.x - that.mainBoard.width / 2;
    let yyy = that.mousePos.y - that.mainBoard.y - that.mainBoard.height / 2;
    // console.log(xxx,yyy);
    that.svgMoveX += (xxx - (xxx / oldzoom) * that.svgZoom) / that.svgZoom;
    that.svgMoveY += (yyy - (yyy / oldzoom) * that.svgZoom) / that.svgZoom;
    that.BorderCheck();
  }

  mainMousewheel(e: any) {
    // mainBridgeSvg.addEventListener("mousewheel", (e: any) => {
    if (e.ctrlKey) {
      // CTRL // MACBOOK
      this.zoomFkt(e, 50); // MACBOOK TRACKPAD ZOOM:
    } else if (e.altKey) {
      // this.zoomFkt(e, 500);
      // this.moveFkt(e, 1)
    } else if (e.shiftKey) {
      this.moveFkt(e, 1);
    } else if (e.metaKey) {
      this.zoomFkt(e, 500);
    } else {
      // this.moveFkt(e, 1);
      //   NORMAL MOVE!
      this.zoomFkt(e, 350);
      // this.moveFkt(e, 1)
    }
  }

  mainMousedown(e: any) {
    if (e.altKey) {
      if (e.ctrlKey) {
        e.preventDefault();
        e.stopImmediatePropagation();
      }
    }
    if (e.buttons === 4 || (e.buttons === 1 && this.spacekeydown)) {
      this.middleclick = true;
    }
  }

  initAppKeyPresses() {
    (document as any).addEventListener("keydown", (e: any) => {
      // console.log(e.code);
      // if (e.code === "Space") {
      if (e.code === "AltLeft") {
        // if (e.code === "ControlLeft") {
        this.spacekeydown = true;
        this.middleclick = true;
      }
    });

    (document as any).addEventListener("keyup", (e: any) => {
      // if (e.code === "Space") {
      if (e.code === "AltLeft") {
        // if (e.code === "ControlLeft") {
        this.spacekeydown = false;
        this.middleclick = false;
      }
    });

    (document as any).addEventListener("mouseup", (e: any) => {
      this.middleclick = false;
    });

    window.addEventListener("resize", (e) => {
      this.init();
    });
  }

  // makebgimg(img: any) {
  //   let imgUrl =
  //     "https://images.pexels.com/photos/6662604/pexels-photo-6662604.jpeg";
  //   return "background-image: url('" + imgUrl + "')";
  // }
  // TM(e: any) {
  //   let that: any = this
  //     if (e.touches.length == 1) {
  //         that.mousePos = { x: e.touches[0].clientX, y: e.touches[0].clientY }
  //     } else if (e.touches.length == 2) {
  //         that.mousePos = { x: (e.touches[0].clientX + e.touches[1].clientX) / 2, y: (e.touches[0].clientY + e.touches[1].clientY) / 2 }
  //     }
  // }
}
